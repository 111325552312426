import type { TrackingBenefit } from "./Tracking";
import type { DataContainer, Feature, FeatureStatus } from "@otto-ec/tracking-bct";

const TRACKING_PARENT_ID = "FT9-benefit-main";

export function buildTrackingPayloadCinema(status: FeatureStatus, cinemaDom: HTMLElement): Feature {
	const filledSlots = cinemaDom.getAttribute("data-filled-slots"); /*                                             */
	const [position, slot] = getFeaturePosition(cinemaDom);
	const cinemaCount = cinemaDom.parentElement?.parentElement?.getAttribute("data-feature-index") ?? 1; /*                                                      */

	const labels: DataContainer = {
		benefit_CinemaCount: [`${cinemaCount}`],
		benefit_FilledSlots: [`${String(filledSlots)}`],
	};

	if (slot) {
		labels.benefit_Slot = [slot];
	}

	return {
		id: `${TRACKING_PARENT_ID}-${slot}-${position}`,
		name: "BenefitCinema",
		status,
		labels,
		position,
	};
}

export function getFeaturePosition(cinemaDom: HTMLElement): [number, string | undefined] {
	/*                                                         */
	const topSlotPosition = cinemaDom.parentElement?.parentElement?.dataset.topSlot;
	if (topSlotPosition) return [Number.parseInt(topSlotPosition), "top"];

	/*                                                             */
	const tilelistSlotPosition = cinemaDom.parentElement?.parentElement?.dataset.tilelistSlot;
	if (tilelistSlotPosition) return [Number.parseInt(tilelistSlotPosition), "tilelist"];

	return [0, undefined];
}

export function buildTrackingPayloadSlide(status: FeatureStatus, benefitDom: HTMLElement, parentId: string): Feature {
	const tracking = JSON.parse(benefitDom.dataset.tracking ?? "{}") as TrackingBenefit;

	const benefitPosition = tracking.position;
	return {
		id: `${parentId}-${tracking.id}${benefitPosition}`,
		name: tracking.name,
		status,
		labels: tracking.labels,
		parentId,
		position: benefitPosition,
	};
}
